<template>
  <div class="v-themes-info">
    <div class="black-mask">
    <breadcrumb />

    <h2 class="themes-info-title">{{ route.params.category }}</h2>

    <p class="themes-info-desc">
      Blast your project into another dimension with our BAFTA winning composers, action-packed albums and
      console-centric playlists! Explore below to find the soundtrack your game deserves, or compete against colleagues
      in our very own Sync Sprint game.
    </p>

    <div class=" g-content">
      <div class="main">
        <div class="info-img" :style="{ 'background-image': `url(${mainImage})` }"></div>

        <section class="info-section info-section--sub">
          <p class="sub-title">Play the Sync Sprint</p>
          <p class="sub-title-desc">
            Play our Sync Sprint game, earn a coveted place on the global leaderboard and be crowned champion of the
            office. Warning: It’s highly addictive!
          </p>

          <div class="btn-wrap">
            <mi-button type="border">Button title</mi-button>
          </div>
        </section>

        <section class="info-section">
          <!-- <ul class="popular-playlists-box-list">
            <li class="popular-playlists-box-list__item" v-for="i of 4" :key="i">
              <div class="popular-playlists-box-list__item__box">
                <playlists-box-popular hideDescBelowPortrait />
              </div>
            </li>
          </ul> -->

          <theme-info-playlists-rwd-transform :data="themeInfoPlaylistsMockData">
            <template v-slot:header>
              <p class="sub-title">{{ route.params.category }} Playlists</p>
            </template>
            <template v-slot="playliastsData">
              <playlists-box-popular :data="playliastsData.data" hideDescBelowPortrait />
            </template>
          </theme-info-playlists-rwd-transform>
        </section>

        <section class="info-section">
          <!-- <ul class="popular-albums-box-list">
            <li class="popular-albums-box-list__item" v-for="i of 4" :key="i">
              <div class="popular-albums-box-list__item__box">
                <albums-box-popular hideTrackDescBelowPortrait />
              </div>
            </li>
          </ul> -->

          <theme-info-playlists-rwd-transform :data="themeInfoAlbumMockData">
            <template v-slot:header>
              <p class="sub-title">{{ route.params.category }} Albums</p>
            </template>
            <template v-slot="playliastsData">
              <albums-box-popular :data="playliastsData.data" hideTrackDescBelowPortrait />
            </template>
          </theme-info-playlists-rwd-transform>
        </section>

        <section class="info-section">
          <p class="sub-title">{{ route.params.category }} Tracks</p>

          <div class="theme-info-tracks">
            <info-tracks />
          </div>
        </section>

        <section class="extra-section">
          <div class="extra-box">
            <div class="extra-box__img-box">
              <img class="extra-box__img-box__img" src="@/assets/about/img_kv.png" />
            </div>
            <div class="extra-box__text extra-box__left-margin">
              <p class="extra-box__text__title">Embracing eco!</p>
              <p class="extra-box__text__desc">
                In celebration of Earth Day’s 50th anniversary, we are proud to introduce Atmosphere’s latest release…
                Earth Day! Produced with an active step in reducing our carbon footprint, our composers worked towards a
                greener and more sustainable production, delivering beautifully moving and rousing scores that capture
                the majesty of our incredible earth in all its natural awe and wonder.
              </p>
              <div class="extra-box__btn-wrap">
                <mi-button type="border">Butotn title</mi-button>
              </div>
            </div>
          </div>

          <div class="extra-box extra-box--bottom">
            <div class="extra-box__img-box extra-box__left-margin">
              <img class="extra-box__img-box__img" src="@/assets/about/img_kv.png" />
            </div>
            <div class="extra-box__text">
              <p class="extra-box__text__title">Embracing eco!</p>
              <p class="extra-box__text__desc">
                In celebration of Earth Day’s 50th anniversary, we are proud to introduce Atmosphere’s latest release…
                Earth Day! Produced with an active step in reducing our carbon footprint, our composers worked towards a
                greener and more sustainable production, delivering beautifully moving and rousing scores that capture
                the majesty of our incredible earth in all its natural awe and wonder.
              </p>
            </div>
          </div>
        </section>
      </div>

      <div class="btn-wrap">
        <mi-button type="border-white" @click="toTheme">Back</mi-button>
      </div>
    </div>

    <site-map />
    </div>
  </div>
</template>
<script>
// import { defineComponent, computed } from 'vue';
import { defineComponent, computed } from 'vue';
// import { useRoute } from 'vue-router';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import PlaylistsBoxPopular from '@/components/Explore/PlaylistsBoxPopular.vue';
import AlbumsBoxPopular from '@/components/Explore/AlbumsBoxPopular.vue';
import MiButton from '@/components/Global/MiButton.vue';
import Breadcrumb from '@/views/Explore/Breadcrumb.vue';
import InfoTracks from '@/components/Explore/InfoTracks.vue';
import { useSiteStore } from '@/store/site';
import ThemeInfoPlaylistsRwdTransform from '@/components/Global/ThemeInfoPlaylistsRwdTransform.vue';
import { themeInfoPlaylistsMockData } from '@/config/mockData/playlistsBox';
import { themeInfoAlbumMockData } from '@/config/mockData/albumsBox';
import SiteMap from '@/views/Home/SiteMap.vue';

export default defineComponent({
  name: 'ThemesInfo',
  components: {
    Breadcrumb,
    PlaylistsBoxPopular,
    AlbumsBoxPopular,
    MiButton,
    InfoTracks,
    ThemeInfoPlaylistsRwdTransform,
    SiteMap,
  },
  setup() {
    const siteStore = useSiteStore();
    const route = useRoute();
    const router = useRouter();

    const toTheme = () => {
      router.push({
        name: 'explore-type',
        params: {
          type: 'themes',
        },
      });
    };

    // 離開此頁時，清除背景
    onBeforeRouteLeave(() => {
      siteStore.setBackgroundImage(false);
    });

    return {
      themeInfoPlaylistsMockData,
      themeInfoAlbumMockData,
      mainImage: computed(() => siteStore.backgroundImage),
      route,
      toTheme,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/blackMask.scss';
.v-themes-info {
  padding-top: 60px;
  // padding-bottom: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  @include h100();
  overflow-y: auto;
}

.themes-info-title {
  margin-top: 40px;
  text-align: center;
  @include font-style($c-white, 24, bold, 2.25px, 32px);
  position: relative;
  z-index: 10;
}

.themes-info-desc {
  margin: 16px auto 0;
  width: 100%;
  text-align: center;
  @include font-style($c-white, 14, 400, 0, 24px);
  @include padding(0 40px);
  position: relative;
  z-index: 10;
}

.main {
  background-color: $c-white;
  border-radius: 10px;
  @include padding(15px);
  position: relative;
  box-shadow: 0px 0px 10px rgba($c-black, 0.05);
  margin-top: 40px;
}

.info-img {
  height: 180px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 20px;
  border-radius: 5px;
}

.info-section {
  &--sub {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  & + & {
    margin-top: 50px;
  }
}

.sub-title {
  @include font-style($c-black, 18, bold, 0, 24px);
  text-align: center;
  margin-bottom: 20px;
}

.sub-title-desc {
  @include font-style($c-assist4, 16, normal, 0, 24px);
  // margin-top: 8px;
}

.btn-wrap {
  margin-top: 28px;
  padding-bottom: 100px;
  position: relative;
  z-index: 10;
}

.popular-playlists-box-list {
  @include flex();
  flex-wrap: wrap;
  margin: 20px -7px 0;

  &__item {
    flex: none;
    width: 50%;
  }
}

.popular-albums-box-list {
  @include flex();
  flex-wrap: wrap;
  margin: 20px -7px 0;

  &__item {
    flex: none;
    width: 50%;
    margin-top: 20px;

    &:first-child,
    &:nth-child(2) {
      margin-top: 0;
    }
  }
}

.theme-info-tracks {
  margin-top: 20px;
  margin-bottom: 40px;
  background-color: $c-white3;
  @include padding(20px 8px);
  border-radius: 5px;
  width: 100%;
}

.extra-section {
  margin-top: 20px;
}
.extra-box {
  @include flex(flex-start, flex-start, column);

  &--bottom {
    margin-top: 52px;
  }

  &__text {
    margin-top: 20px;

    &__title {
      @include font-style($c-black, 18, bold, 0, 24px);
    }

    &__desc {
      @include font-style($c-assist6, 16, normal, 0, 24px);
      margin-top: 10px;
    }
  }

  &__img-box {
    flex: none;
    width: 100%;

    &__img {
      width: 100%;
      vertical-align: bottom;
      border-radius: 5px;
    }
  }

  &__btn-wrap {
    margin-top: 28px;
    @include flex(center);
  }

  &__left-margin {
    margin-left: 0px;
  }
}

@media screen and (min-width: $tablet) {
  .v-themes-info {
    padding-top: 80px;
    // padding-bottom: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .themes-info-title {
    margin-top: 63px;
    text-align: center;
    @include font-style($c-white, 45, bold, 2.25px, 60px);
  }

  .themes-info-desc {
    margin: 16px auto 0;
    width: 660px;
    text-align: center;
    @include font-style($c-white, 16, 400, 0, 24px);
  }

  .main {
    @include max-width(1366);
    background-color: $c-white;
    border-radius: 10px;
    @include padding(115px 86px 138px);
    position: relative;
    box-shadow: 0px 0px 10px rgba($c-black, 0.05);
    margin-top: 80px;
  }

  .info-img {
    height: 450px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 40px;
    border-radius: 5px;
  }

  .info-section {
    &--sub {
      width: 660px;
      margin: 0 auto;
      text-align: center;
    }

    & + & {
      margin-top: 100px;
    }
  }

  .sub-title {
    @include font-style($c-black, 30, bold, 0, 40px);
    text-align: center;
  }

  .sub-title-desc {
    @include font-style($c-assist4, 16, normal, 0, 24px);
    margin-top: 16px;
  }

  .btn-wrap {
    margin-top: 50px;
  }

  .popular-playlists-box-list {
    @include flex();
    flex-wrap: wrap;
    margin: 28px -7px 0;

    &__item {
      flex: none;
      width: 25%;
    }
  }

  .popular-albums-box-list {
    @include flex();
    flex-wrap: wrap;
    margin: 28px -7px 0;

    &__item {
      flex: none;
      width: 25%;

      &:first-child,
      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }

  .theme-info-tracks {
    @include padding(24px);
  }

  .extra-section {
    margin-top: 112px;
  }
  .extra-box {
    @include flex();

    &--bottom {
      margin-top: 80px;

      .extra-box__img-box {
        order: 2;
      }

      .extra-box__text {
        order: 1;
      }
    }

    &__text {
      margin-top: 0;
      &__title {
        @include font-style($c-black, 30, bold, 0, 36px);
      }

      &__desc {
        @include font-style($c-assist6, 16, normal, 0, 24px);
        margin-top: 16px;
      }
    }

    &__img-box {
      flex: none;
      width: 400px;

      &__img {
        width: 100%;
        vertical-align: bottom;
        border-radius: 5px;
      }
    }

    &__btn-wrap {
      @include flex(flex-start);
      margin-top: 40px;
    }

    &__left-margin {
      margin-left: 37px;
    }
  }
}

.btn-wrap {
  @include flex(center);
}

@media screen and (min-width: $sm-pc) {
  .extra-box {
    &__img-box {
      width: 600px;
    }
  }
}
</style>

const siteMap = [
  {
    label: '探索',
    subList: [
      {
        label: '主題',
        route: {
          name: 'explore-type',
          params: {
            type: 'themes',
          },
        },
      },
      {
        label: '氣氛',
        route: {
          name: 'explore-type',
          params: {
            type: 'moods',
          },
        },
      },
      {
        label: '曲風',
        route: {
          name: 'explore-type',
          params: {
            type: 'genres',
          },
        },
      },
      {
        label: '歌單',
        route: {
          name: 'explore-type',
          params: {
            type: 'playlists',
          },
        },
      },
      {
        label: '專輯',
        route: {
          name: 'explore-type',
          params: {
            type: 'albums',
          },
        },
      },
      {
        label: '品牌',
        route: {
          name: 'explore-type',
          params: {
            type: 'labels',
          },
        },
      },
    ],
  },
  {
    label: '會員專區',
    subList: [
      {
        label: '我的帳戶',
        route: { name: 'my-profile-personal-details' },
      },
      {
        label: '所有歌單',
        route: { name: 'my-music-playlists' },
      },
      {
        label: '搜尋紀錄',
        route: { name: 'my-music-search-history' },
      },
      {
        label: '播放紀錄',
        route: { name: 'my-music-play-history' },
      },
      {
        label: '下載紀錄',
        route: { name: 'my-music-download-history' },
      },
      {
        label: '影音結合體會',
        route: { name: 'my-music-preview-tool' },
      },
    ],
  },
  {
    label: '法律聲明',
    subList: [
      {
        label: '使用條款',
        route: { name: 'licensing-terms' },
      },
      {
        label: '隱私權政策',
        route: { name: 'licensing-privacy' },
      },
      {
        label: 'COOKIES 政策',
        route: { name: 'licensing-cookies' },
      },
    ],
  },
  {
    label: '關於',
    subList: [
      {
        label: '授權說明',
        route: { name: 'licensing' },
      },
      {
        label: '關於我們',
        route: { name: 'front-about-us' },
      },
      {
        label: '聯絡我們',
        route: { name: 'front-contact' },
      },
    ],
  },
];

export default siteMap;

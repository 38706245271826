export const popularAlbumMockData = [
  {
    id: '1',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '2',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '3',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '4',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },

];

export const normalAlbumMockData = [
  {
    id: '1',
    img: '',
    title: 'Advertising - Christmas in July',
    track: 'HK-19',
  },
  {
    id: '2',
    img: '',
    title: 'Advertising - Christmas in July',
    track: 'HK-19',
  },
  {
    id: '3',
    img: '',
    title: 'Advertising - Christmas in July',
    track: 'HK-19',
  },
  {
    id: '4',
    img: '',
    title: 'Advertising - Christmas in July',
    track: 'HK-19',
  },
  {
    id: '5',
    img: '',
    title: 'Advertising - Christmas in July',
    track: 'HK-19',
  },
  {
    id: '6',
    img: '',
    title: 'Advertising - Christmas in July',
    track: 'HK-19',
  },
  {
    id: '7',
    img: '',
    title: 'Advertising - Christmas in July',
    track: 'HK-19',
  },
  {
    id: '8',
    img: '',
    title: 'Advertising - Christmas in July',
    track: 'HK-19',
  },

];

export const themeInfoAlbumMockData = [
  {
    id: '1',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '2',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '3',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '4',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },

];

export const relativeAlbumMockData = [
  {
    id: '1',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '2',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '3',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '4',
    img: '',
    title: 'GRAVITY - The Infinite Universe',
    track: 'HK-19',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },

];

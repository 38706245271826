export const moodsCategory = {
  aggressive: { ch: '激進', en: 'AGGRESSIVE' },
  atmospheric: { ch: '氛圍', en: 'ATMOSPHERIC' },
  beauty: { ch: '優美', en: 'BEAUTY' },
  building: { ch: '漸強', en: 'BUILDING' },
  dramatic: { ch: '戲劇性', en: 'DRAMATIC' },
  emotional: { ch: '煽情', en: 'EMOTIONAL' },
  energy: { ch: '力量', en: 'ENERGY' },
  epic: { ch: '史詩', en: 'EPIC' },
  fantasy: { ch: '夢幻式', en: 'FANTASY' },
  fun: { ch: '趣味', en: 'FUN' },
  'happ-positive': { ch: '開心  正面', en: 'HAPPY / POSITIVE' },
  heroic: { ch: '英勇', en: 'HEROIC' },
  humour: { ch: '幽默', en: 'HUMOUR' },
  'laidback-chilled': { ch: '悠閒', en: 'LAID BACK / CHILLED' },
  magical: { ch: '魔幻', en: 'MAGICAL' },
  minimal: { ch: '簡約', en: 'MINIMAL' },
  motivational: { ch: '勵志', en: 'MOTIVATIONAL' },
  quirky: { ch: '詭怪', en: 'QUIRKY' },
  romantic: { ch: '浪漫', en: 'ROMANTIC' },
  sad: { ch: '悲傷', en: 'SAD' },
  sexy: { ch: '性感', en: 'SEXY' },
  suspense: { ch: '懸疑', en: 'SUSPENSE' },
  uplifting: { ch: '振奮', en: 'UPLIFTING' },
  working: { ch: '工作', en: 'WORKING' },
};

export const genresCategory = {
  acoustic: { ch: '原音樂', en: 'ACOUSTIC' },
  blues: { ch: '藍調', en: 'BLUES' },
  classical: { ch: '古典', en: 'CLASSICAL' },
  country: { ch: '鄉村音樂', en: 'COUNTRY' },
  'dance-electronica': { ch: '電子舞曲', en: 'DANCE / ELECTRONICA' },
  drones: { ch: '持續音', en: 'DRONES' },
  dubstep: { ch: '迴響貝斯', en: 'DUBSTEP' },
  easyListening: { ch: '輕音樂', en: 'EASY LISTENING' },
  electroPop: { ch: '電子流行曲', en: 'ELECTRO POP' },
  folk: { ch: '民歌', en: 'FOLK' },
  funk: { ch: '放克', en: 'FUNK' },
  hiphop: { ch: '嘻哈', en: 'HIP HOP' },
  house: { ch: '浩室', en: 'HOUSE' },
  'indie-alternativerock': { ch: '獨立 / 非主流搖滾', en: 'INDIE / ALTERNATIVE ROCK' },
  jazz: { ch: '爵士', en: 'JAZZ' },
  kitsch: { ch: '俗氣', en: 'KITSCH' },
  latin: { ch: '拉丁', en: 'LATIN' },
  march: { ch: '進行曲', en: 'MARCH' },
  metal: { ch: '金屬', en: 'METAL' },
  opera: { ch: '歌劇', en: 'OPERA' },
  pop: { ch: '流行曲', en: 'POP' },
  rock: { ch: '搖滾', en: 'ROCK' },
  soul: { ch: '騷靈', en: 'SOUL' },
  'urban-r&b': { ch: '都市 / 節奏藍調', en: ' URBAN / R&B' },
};

const allCategories = {
  ...moodsCategory,
  ...genresCategory,
};

export default allCategories;

<template>
  <div class="c-box-rwd-transform">
    <slot name="header"> </slot>
    <ul class="box-list">
      <li class="box-list__item" v-for="d of data" :key="d.id">
        <div class="box-list__item__box">
          <slot :data="d" />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

// 手機平板滑動、電腦正常排列
export default defineComponent({
  name: 'NormalBoxRwdTransfrom',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
<style lang="scss" scoped>
// .c-box-rwd-transform {
//   box-sizing: border-box;
//   padding-left: 20px;
//   width: 100%;
// }
.box-list {
  @include flex();
  flex-wrap: wrap;
  margin: 0 -7px;

  &__item {
    flex: none;
    width: 50%;
    margin-top: 20px;

    &:first-child,
    &:nth-child(2) {
      margin-top: 0;
    }

    &__box {
      @include padding(0 7px);
    }
  }
}

@media screen and (min-width: $mobile) {
  // .c-box-rwd-transform {
  //   padding-left: calc((100% - 90%)/2);
  //   margin: 0 auto;
  // }

  // .box-list {
  //   padding-right: calc((100% - 90%) / 2);
  // }
}

// @media screen and (min-width: $portrait) {
//   .box-list {
//     &__item {
//       width: 33.3333333%;

//       &:nth-child(3) {
//         margin-top: 0px;
//       }
//     }
//   }
// }

@media screen and (min-width: $portrait) {
  // .c-box-rwd-transform {
  //   padding-left: 0;
  // }
  .box-list {
    @include flex();
    flex-wrap: wrap;
    margin: 0 -10px;

    &__item {
      flex: none;
      width: 25%;
      margin-top: 46px;

&:nth-child(3),
      &:nth-child(4) {
        margin-top: 0px;
      }

      &__box {
        @include padding(0 10px);
      }
    }
  }
}

</style>

<template>
  <div class="c-breadcrumb">
    <ul class="breadcrumb-list">
      <li class="breadcrumb-list__item" v-for="(item, idx) of fullBreadcrumb" :key="item.label">
        <span class="breadcrumb-list__item__label" @click="toPage(item.routeInfo)">
          {{ item.label }}
        </span>

        <img
          class="breadcrumb-list__item__arrow"
          v-show="fullBreadcrumb.length - 1 !== idx"
          src="@/assets/icon/icon_arrow.svg"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import allCategories from '@/config/categoryConfig';
import router from '@/router';

const defaultBreadcrumb = [
  {
    label: '首頁',
    routeInfo: {
      name: 'home',
    },
  },
  {
    label: '探索',
    routeInfo: {
      name: 'explore-type',
      params: {
        type: 'themes',
      },
    },
  },
];

export default defineComponent({
  name: 'BreadCrumb',
  setup() {
    const route = useRoute();

    const fullBreadcrumb = computed(() => {
      const breadcrumb = JSON.parse(JSON.stringify(defaultBreadcrumb));

      switch (route.name) {
        case 'explore-themes-info':
          breadcrumb.push({
            label: '主題',
            routeInfo: {
              name: 'explore-type',
              params: {
                type: 'themes',
              },
            },
          });
          breadcrumb.push({
            label: route.params.category,
          });
          break;
        case 'explore-moods-info':
          breadcrumb.push({
            label: '氣氛',
            routeInfo: {
              name: 'explore-type',
              params: {
                type: 'moods',
              },
            },
          });
          breadcrumb.push({
            label: route.params.category,
          });

          break;

        case 'explore-genres-info':
          breadcrumb.push({
            label: '曲風',
            routeInfo: {
              name: 'explore-type',
              params: {
                type: 'genres',
              },
            },
          });
          breadcrumb.push({
            // label: `${allCategories[route.params.category].ch} ${allCategories[route.params.category].en}`,
            label: route.params.category,
          });

          break;

        case 'explore-labels-info':
          breadcrumb.push({
            label: '品牌',
            routeInfo: {
              name: 'explore-type',
              params: {
                type: 'labels',
              },
            },
          });
          breadcrumb.push({
            label: route.params.category,
          });

          break;

        case 'explore-albums-info':
          breadcrumb.push({
            label: '專輯',
            routeInfo: {
              name: 'explore-type',
              params: {
                type: 'albums',
              },
            },
          });
          breadcrumb.push({
            label: route.query.title,
          });

          break;

        case 'explore-playlists-info':
          breadcrumb.push({
            label: '專輯',
            routeInfo: {
              name: 'explore-type',
              params: {
                type: 'playlists',
              },
            },
          });
          breadcrumb.push({
            label: route.query.title,
          });

          break;

        default:
          // breadcrumb.push({
          //   label: allCategories[route.params.category].ch,
          // });
          breadcrumb.push({
            label: allCategories[route.params.category].ch,
          });
          break;
      }

      return breadcrumb;
    });

    const toPage = (routeInfo) => {
      if (routeInfo) {
        router.push(routeInfo);
      }
    };

    return {
      fullBreadcrumb,
      toPage,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-breadcrumb {
  height: 40px;
  @include flex();
  // margin-top: 10px;
  @include max-width(1700);
  position: relative;
  z-index: 10;
}
.breadcrumb-list {
  @include flex();

  &__item {
    @include flex();

    &__label {
      @include font-style($c-white, 12);
      cursor: pointer;
    }

    &__arrow {
      margin: 0 10px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .c-breadcrumb {
    height: 70px;
    @include flex();
    @include max-width(1700);
  }
  .breadcrumb-list {
    @include flex();

    &__item {
      @include flex();

      &__label {
        @include font-style($c-white, 16);
        cursor: pointer;
      }

      &__arrow {
        margin: 0 10px;
      }
    }
  }
}
</style>

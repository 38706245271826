<template>
  <div class="f-site-map" :class="[`f-site-map--pb-adjust--${footerBottomExtraPaddingType}`]">
    <footer class="f-site-map__content g-content show-tablet">
      <div class="contact-us">
        <div class="contact-us-box">
          <red-circle-button
            label="有疑問嗎？請聯絡我們。"
            label-color="#fff"
            label-bg="#242424"
            :routeInfo="{ name: 'front-contact' }"
          />
          <div class="contact-us-box__social-media">
            <img class="contact-us-box__social-media__icons" src="@/assets/icon/footer_fb.svg" />
            <img class="contact-us-box__social-media__icons" src="@/assets/icon/footer_ins.svg" />
          </div>
        </div>
      </div>
      <div class="site-map-wrap">
        <ul class="main-site-list" v-for="main of siteMap" :key="main.label">
          <li class="main-site-list__item">
            <span class="main-site-list__item__label">
              {{ main.label }}
            </span>
            <ul class="sub-site-list">
              <li class="sub-site-list__item" v-for="sub of main.subList" :key="sub.label">
                <router-link :to="sub.route">
                  <span class="sub-site-list__item__label">
                    {{ sub.label }}
                  </span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </footer>

    <footer class="footer">
      <div class="footer__divide-line"></div>
      <p class="footer__text">音韶文化事業有限公司版權所有</p>
      <p class="footer__text">© {{ currYear }} POINT CULTURE. ALL RIGHTS RESERVED.</p>
    </footer>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import siteMap from '@/config/siteMap';
import RedCircleButton from '@/components/Home/RedCircleButton.vue';
import { useMusicPlayerStore } from '@/store/musicPlayer';
import currYear from '@/utils/currYear';

export default defineComponent({
  name: 'SiteMap',
  components: { RedCircleButton },
  setup() {
    const musicPlayerStore = useMusicPlayerStore();
    const footerBottomExtraPaddingType = computed(() => musicPlayerStore.footerBottomExtraPaddingType);

    return {
      siteMap,
      footerBottomExtraPaddingType,
      currYear,
    };
  },
});
</script>
<style lang="scss" scoped>
.footer {
  background-color: $c-black2;
  @include padding(24px 0 40px);

  &__divide-line {
    display: none;
    width: 100%;
    height: 1px;
    background-color: $c-text1;
    margin-top: 80px;
    margin-bottom: 40px;
  }

  &__text {
    text-align: center;
    @include font-style($c-text1, 14, 400, 0, 22px);
  }
}

.f-site-map {
  background-color: $c-black2;
  padding-bottom: 50px;
  position: relative;
  z-index: 10;
}

.show-tablet {
  display: none;
}

@media screen and (min-width: $tablet) {
  .show-mobile {
    display: none;
  }

  .show-tablet {
    display: block;
  }

  .f-site-map {
    @include padding(75px 110px 0px);

    &--pb-adjust {
      &--circle {
        padding-bottom: 0px;
      }

      &--full {
        padding-bottom: 110px;
      }

      &--simple {
        padding-bottom: 40px;
      }
    }

    &__content {
      @include flex(space-between, flex-start);
    }
  }

  .contact-us-box {
    margin-top: -30px;

    &__social-media {
      margin-left: 70px;

      &__icons {
        width: 37px;
        cursor: pointer;

        & + & {
          margin-left: 37px;
        }
      }
    }
  }

  .site-map-wrap {
    @include flex(flex-start, flex-start);
  }

  .main-site-list {
    & + & {
      margin-left: 30px;
    }

    &__item {
      &__label {
        @include font-style($c-white, 16, 400, 0.8px, 20px);
      }
    }
  }

  .sub-site-list {
    margin-top: 37px;

    &__item {
      cursor: pointer;

      & + & {
        margin-top: 20px;
      }

      &__label {
        @include font-style($c-text4, 16, 400, 0.8px, 20px);
        transition: 0.3s;

        &:hover {
          color: $c-text1;
        }
      }
    }
  }

  .footer {
    text-align: center;

    &__divide-line {
      display: block;
    }

    &__text {
      display: inline-block;
      @include font-style($c-text1, 14, 400, 0.7px);
      margin-left: 20px;
    }
  }
}

@media screen and (min-width: $sm-pc) {
  .main-site-list {
    & + & {
      margin-left: 115px;
    }
  }
}
</style>

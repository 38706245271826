export const popularPlaylistsMockData = [
  {
    id: '1',
    img: '',
    title: 'International Women’s Day 2020',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '2',
    img: '',
    title: 'International Women’s Day 2020',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '3',
    img: '',
    title: 'International Women’s Day 2020',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
  {
    id: '4',
    img: '',
    title: 'International Women’s Day 2020',
    desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
  },
];

export const normalPlaylistsMockData = [
  {
    id: '1',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '2',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '3',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '4',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '5',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '6',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '7',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '8',
    img: '',
    title: 'Advertising - Christmas in July',
  },

];

export const themeInfoPlaylistsMockData = [
  {
    id: '1',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '2',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '3',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '4',
    img: '',
    title: 'Advertising - Christmas in July',
  },

];

export const relativePlaylistsMockData = [
  {
    id: '1',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '2',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '3',
    img: '',
    title: 'Advertising - Christmas in July',
  },
  {
    id: '4',
    img: '',
    title: 'Advertising - Christmas in July',
  },

];

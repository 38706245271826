<template>
  <div class="c-albums-box" :class="{ 'c-albums-box--white': color === 'white' }" @click="toAlbumsInfo">
    <div class="albums-box item-hover--bottom">
      <img class="albums-box__img" src="@/assets/explore/albums_img.png" />
      <div class="albums-box__overlay"></div>
      <p class="albums-box__learn-more">了解更多</p>
    </div>
    <p class="albums-title">{{ data.title }}</p>
    <p class="albums-track" >{{ data.track }}</p>
    <p class="albums-desc" :class="{ 'albums-desc--hide-track-desc-below-portrait': hideTrackDescBelowPortrait }">{{ data.desc }}</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'AlbumsBoxPopular',
  props: {
    data: {
      type: Object,
      default: () => ({
        id: '123',
        img: '',
        title: 'GRAVITY - The Infinite Universe',
        track: 'HK-19',
        desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
      }),
    },
    // 字的顏色
    color: {
      type: String,
      default: 'black',
    },
    hideTrackDescBelowPortrait: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();

    const toAlbumsInfo = () => {
      router.push({
        name: 'explore-albums-info',
        query: {
          id: props.data.id,
          title: props.data.title,
        },
      });
    };

    return {
      toAlbumsInfo,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-albums-box {
  // @include padding(0px 7px);
  // @include padding(24px 7px);
  white-space: normal;

  &--white {
    .albums-title {
      color: $c-white;
    }

    .albums-track {
      color: $c-assist2;
    }

    .albums-desc {
      color: $c-white;
    }
  }
}
.albums-box {
  position: relative;
  cursor: pointer;
  border-radius: 5px;

  overflow: hidden;

  &:hover {
    .albums-box__overlay {
      display: inline-block;
      background-color: rgba($c-black, 0.5);
    }

    .albums-box__learn-more {
      opacity: 1;
    }
  }

  &__img {
    width: 100%;
    vertical-align: bottom;
  }

  &__overlay {
    @include position(tl, 0, 0);
    width: 100%;
    height: 100%;
  }

  &__learn-more {
    @include position(center);
    @include font-style($c-white, 12, 400, 0.8px);
    @include padding(0 0 0 15px);
    opacity: 0;
    transition: 0.3s;

    &::before {
      content: '';
      @include circle(6px);
      @include position(tl, 60%, 0);
      transform: translateY(-50%);
      display: inline-block;
      background-color: $c-white;
    }
  }
}

.albums-title {
  margin-top: 20px;
  @include font-style($c-black, 16, bold, 1px);
}

.albums-track {
  margin-top: 6px;
  @include font-style($c-assist6, 14, normal, 1px);

  // themeInfo 使用
  &--hide-track-desc-below-portrait {
    display: none;
  }
}

.albums-desc {

  margin-top: 15px;
  @include font-style($c-assist4, 14, normal, 1px);

  // themeInfo 使用
  &--hide-track-desc-below-portrait {
    display: none;
  }
}

@media screen and (min-width: $tablet) {

  .albums-track {
    display: block;

    &--hide-track-desc-below-portrait {
      display: block;
    }
  }

  .albums-desc {
    display: block;

    &--hide-track-desc-below-portrait {
      display: block;
    }
  }

  .albums-title {
    margin-top: 20px;
    @include font-size(20);
  }

}
</style>
